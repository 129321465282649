import PropTypes from 'prop-types'
import React from 'react'
import RTGLink from '@shared/link'
import styled from 'styled-components'
import { theme } from '@constants/styles'
import Layout from '@components/layout'
import Helmet from 'react-helmet'
import { getRegionZone } from '@helpers/geo-location'
import logo from '../assets/images/RTG_Customer-Care-RTG-Logo short jpg.jpg'
import { useAnalytics } from '../lib/hooks'

const Breadcrumbs = styled.div`
  margin-top: 1rem;
  margin-bottom: 1rem;
  a {
    text-decoration: underline;
  }
  .current {
    margin-left: 5px;
  }
`

const CareLogo = styled.img`
  width: min(50vw, 230px);
  padding: 0 0 10px 5px;
  @media screen and (min-width: ${theme.breakpoint.tablet}) {
    width: min(50vw, 345px);
  }
  @media screen and (min-width: ${theme.breakpoint.desktop}) {
    width: min(50vw, 380px);
  }
`

const WidgetWrapper = styled.div`
  margin-top: 1rem;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;

  .widget {
    width: 48%;
    min-width: 258px;

    h4 {
      font-weight: 600;
    }
  }
`

const Container = styled.div`
  // MOBILE STYLES
  background: ${theme.white};
  padding: 20px;
  a {
    color: ${theme.primary};
    text-decoration: underline;
    &:hover {
      color: ${theme.secondary};
    }
  }
  svg {
    margin-right: 10px;
  }
  h1 {
    font-size: 24px;
    color: ${theme.primary};
    font-weight: ${theme.weight.semiBold};
    text-transform: uppercase;
    text-align: left;
    padding: 0;
    margin-bottom: 24px;
  }
  h2 {
    font-size: 20px;
    color: ${theme.grayDark};
    font-weight: ${theme.weight.semiBold};
    text-transform: uppercase;
    border-bottom: 3px solid ${theme.primary};
    padding-bottom: 3px;
    margin-bottom: 24px;
  }
  h3 {
    font-size: 20px;
    color: ${theme.grayDark};
    font-weight: ${theme.weight.regular};
    margin-bottom: 18px;
    display: flex;
    align-items: center;
  }
  p,
  dl,
  dd {
    font-size: 1rem;
    line-height: 20px;
    margin-bottom: 18px;
  }
  ul {
    list-style: initial;
    padding-left: 24px;
    margin-bottom: 24px;
  }
  .cell {
    margin-bottom: 24px;
    &:first-child {
      border-bottom: 1px solid ${theme.primary};
    }
  }
  .time {
    font-weight: ${theme.weight.light};
    line-height: 20px;
  }
  // TABLET STYLES
  @media screen and (min-width: ${theme.breakpoint.tablet}) {
    padding: 50px;
    h1 {
      font-size: 36px;
    }
    h2 {
      font-size: 28px;
      border-bottom: 5px solid ${theme.primary};
      padding-bottom: 5px;
    }
    h3 {
      font-size: 24px;
    }
  }
  // DESKTOP STYLES
  @media screen and (min-width: ${theme.breakpoint.desktop}) {
    padding: 50px;
    h1 {
      font-size: 40px;
    }
    h2 {
      font-size: 32px;
    }
    h3 {
      font-size: 28px;
    }
    .cell:first-child {
      border-bottom: none;
    }
  }
`

const regionInPR = getRegionZone().region === 'PR'

const GlobeIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="30"
    height="30"
    fill={`${theme.primary}`}
    className="bi bi-globe"
    viewBox="0 0 16 16"
  >
    <path d="M0 8a8 8 0 1 1 16 0A8 8 0 0 1 0 8zm7.5-6.923c-.67.204-1.335.82-1.887 1.855A7.97 7.97 0 0 0 5.145 4H7.5V1.077zM4.09 4a9.267 9.267 0 0 1 .64-1.539 6.7 6.7 0 0 1 .597-.933A7.025 7.025 0 0 0 2.255 4H4.09zm-.582 3.5c.03-.877.138-1.718.312-2.5H1.674a6.958 6.958 0 0 0-.656 2.5h2.49zM4.847 5a12.5 12.5 0 0 0-.338 2.5H7.5V5H4.847zM8.5 5v2.5h2.99a12.495 12.495 0 0 0-.337-2.5H8.5zM4.51 8.5a12.5 12.5 0 0 0 .337 2.5H7.5V8.5H4.51zm3.99 0V11h2.653c.187-.765.306-1.608.338-2.5H8.5zM5.145 12c.138.386.295.744.468 1.068.552 1.035 1.218 1.65 1.887 1.855V12H5.145zm.182 2.472a6.696 6.696 0 0 1-.597-.933A9.268 9.268 0 0 1 4.09 12H2.255a7.024 7.024 0 0 0 3.072 2.472zM3.82 11a13.652 13.652 0 0 1-.312-2.5h-2.49c.062.89.291 1.733.656 2.5H3.82zm6.853 3.472A7.024 7.024 0 0 0 13.745 12H11.91a9.27 9.27 0 0 1-.64 1.539 6.688 6.688 0 0 1-.597.933zM8.5 12v2.923c.67-.204 1.335-.82 1.887-1.855.173-.324.33-.682.468-1.068H8.5zm3.68-1h2.146c.365-.767.594-1.61.656-2.5h-2.49a13.65 13.65 0 0 1-.312 2.5zm2.802-3.5a6.959 6.959 0 0 0-.656-2.5H12.18c.174.782.282 1.623.312 2.5h2.49zM11.27 2.461c.247.464.462.98.64 1.539h1.835a7.024 7.024 0 0 0-3.072-2.472c.218.284.418.598.597.933zM10.855 4a7.966 7.966 0 0 0-.468-1.068C9.835 1.897 9.17 1.282 8.5 1.077V4h2.355z" />
  </svg>
)

const PhoneIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="30"
    height="30"
    fill={`${theme.primary}`}
    className="bi bi-telephone"
    viewBox="0 0 16 16"
  >
    <path d="M3.654 1.328a.678.678 0 0 0-1.015-.063L1.605 2.3c-.483.484-.661 1.169-.45 1.77a17.568 17.568 0 0 0 4.168 6.608 17.569 17.569 0 0 0 6.608 4.168c.601.211 1.286.033 1.77-.45l1.034-1.034a.678.678 0 0 0-.063-1.015l-2.307-1.794a.678.678 0 0 0-.58-.122l-2.19.547a1.745 1.745 0 0 1-1.657-.459L5.482 8.062a1.745 1.745 0 0 1-.46-1.657l.548-2.19a.678.678 0 0 0-.122-.58L3.654 1.328zM1.884.511a1.745 1.745 0 0 1 2.612.163L6.29 2.98c.329.423.445.974.315 1.494l-.547 2.19a.678.678 0 0 0 .178.643l2.457 2.457a.678.678 0 0 0 .644.178l2.189-.547a1.745 1.745 0 0 1 1.494.315l2.306 1.794c.829.645.905 1.87.163 2.611l-1.034 1.034c-.74.74-1.846 1.065-2.877.702a18.634 18.634 0 0 1-7.01-4.42 18.634 18.634 0 0 1-4.42-7.009c-.362-1.03-.037-2.137.703-2.877L1.885.511z" />
  </svg>
)

const Row = props => {
  const { children } = props
  return (
    <div className="grid-container">
      <div className="grid-x grid-margin-x">
        <div className="cell small-12">{children}</div>
      </div>
    </div>
  )
}

Row.propTypes = {
  children: PropTypes.any,
}

const StaticBreadcrumbs = () => (
  <Breadcrumbs className="breadcrumb grid-x grid-margin-x" role="navigation" aria-label="Breadcrumb">
    <ul className="breadcrumbs-list cell small-12 grid-x">
      <li>
        <RTGLink
          data={{
            slug: '/',
            category: 'customer service',
            action: 'home click',
            label: 'Home',
          }}
        >
          Home
        </RTGLink>
      </li>
      <li className="current">{'>'} Customer Service</li>
    </ul>
  </Breadcrumbs>
)

const ContactInformation = () => (
  <section className="grid-x grid-margin-x">
    <div className="cell small-12 large-6">
      <h3>
        <GlobeIcon /> Online Sales
      </h3>
      <p>
        <RTGLink
          data={{
            slug: '/',
            category: 'customer service',
            action: 'home click',
            label: regionInPR ? 'Roomstogo.pr' : 'Roomstogo.com',
          }}
        >
          {regionInPR ? 'Roomstogo.pr' : 'Roomstogo.com'}
        </RTGLink>{' '}
        has an internet sales staff available to answer website related questions.
      </p>
      <p>Here is how our Internet Sales staff can help:</p>
      <ul>
        <li>
          Assist with purchase from{' '}
          <RTGLink
            data={{
              slug: '/',
              category: 'customer service',
              action: 'home click',
              label: regionInPR ? 'Roomstogo.pr' : 'Roomstogo.com',
            }}
          >
            {regionInPR ? 'Roomstogo.pr' : 'Roomstogo.com'}
          </RTGLink>
        </li>
        <li>Provide more info about a specific piece of furniture or collection</li>
        <li>Help navigate the website</li>
        <li>
          General questions about{' '}
          <RTGLink
            data={{
              slug: '/',
              category: 'customer service',
              action: 'home click',
              label: regionInPR ? 'Roomstogo.pr' : 'Roomstogo.com',
            }}
          >
            {regionInPR ? 'Roomstogo.pr' : 'Roomstogo.com'}
          </RTGLink>
        </li>
      </ul>
      {regionInPR ? (
        <p className="time">
          Monday - Friday <time dateTime="09:00">9:00am</time> - <time dateTime="18:00">6:00pm</time> PR
          <br /> Saturday <time dateTime="09:00">9:00am</time> - <time dateTime="18:00">6:00pm</time> PR
        </p>
      ) : (
        <p className="time">
          Monday - Saturday <time dateTime="09:00">9:00am</time> - <time dateTime="21:00">9:00pm</time> Eastern
          <br /> Sunday <time dateTime="11:00">11:00am</time> - <time dateTime="19:00">7:00pm</time> Eastern
        </p>
      )}
      {regionInPR ? (
        <></>
      ) : (
        <p>
          <RTGLink
            data={{
              target: '_blank',
              url: 'https://roomstogo.service-now.com/csp',
              category: 'customer service',
              action: 'roomstogo csp click',
              label: 'Request Service Online - 24/7',
            }}
          >
            <strong>Request Service Online - 24/7</strong>
          </RTGLink>
        </p>
      )}
      {regionInPR ? (
        <></>
      ) : (
        <p>
          <RTGLink
            data={{
              target: '_blank',
              url: 'order/status',
              category: 'customer service',
              action: 'order status click',
              label: 'Order Status',
            }}
          >
            <strong>Order Status - Track or (Re)Schedule Your Delivery</strong>
          </RTGLink>
        </p>
      )}
      <p>
        <strong>
          Call:{' '}
          <a href={`${regionInPR ? 'tel:787-251-1700' : 'tel:888-709-5380'}`}>
            {regionInPR ? '(787) 251-1700' : '1-888-709-5380'}
          </a>
        </strong>{' '}
        <span>Option #1</span>
      </p>
      <p>
        <strong>
          Text:{' '}
          {regionInPR ? (
            <a href="sms:+7879562784&body=SALES">(787) 956-2784</a>
          ) : (
            <a href="sms:+76667&body=SALES">ROOMS (76667)</a>
          )}
        </strong>{' '}
        <span>Keyword SALES {regionInPR ? 'or CARE' : ''}</span>
      </p>
      <p>
        {regionInPR ? (
          <a href="mailto:ventas@roomstogo.pr">ventas@roomstogo.pr</a>
        ) : (
          <a href="mailto:internetsalessupport@roomstogo.com">internetsalessupport@roomstogo.com</a>
        )}
      </p>
    </div>
    <div className="cell small-12 large-6">
      <h3>
        <PhoneIcon /> Customer Care
      </h3>
      <p>
        The Customer Care Center staff can assist once a purchase has been made, for both online and showroom orders.
        They can help with delivery and other "after the sale" situations.
      </p>
      <p>Here is how our Customer Care Center can help:</p>
      <ul>
        <li>Answer questions regarding delivery</li>
        <li>Schedule follow-up service</li>
        <li>General questions about Rooms To Go</li>
      </ul>
      {regionInPR ? (
        <p className="time">
          Monday - Friday <time dateTime="09:00">9:00am</time> - <time dateTime="18:00">6:00pm</time> PR
          <br /> Saturday <time dateTime="09:00">9:00am</time> - <time dateTime="18:00">6:00pm</time> PR
        </p>
      ) : (
        <p className="time">
          Monday - Friday <time dateTime="08:00">8:00am</time> - <time dateTime="20:00">8:00pm</time> Eastern
          <br /> Saturday <time dateTime="08:00">8:00am</time> - <time dateTime="18:00">6:00pm</time> Eastern
        </p>
      )}
      {regionInPR ? (
        <></>
      ) : (
        <p>
          <RTGLink
            data={{
              target: '_blank',
              url: 'https://roomstogo.service-now.com/csp',
              category: 'customer service',
              action: 'roomstogo csp click',
              label: 'Request Service Online - 24/7',
            }}
          >
            <strong>Request Service Online - 24/7</strong>
          </RTGLink>
        </p>
      )}
      {regionInPR ? (
        <></>
      ) : (
        <p>
          <RTGLink
            data={{
              target: '_blank',
              url: 'order/status',
              category: 'customer service',
              action: 'order status click',
              label: 'Order Status',
            }}
          >
            <strong>Order Status - Track or (Re)Schedule Your Delivery</strong>
          </RTGLink>
        </p>
      )}
      <p>
        <strong>
          Call:{' '}
          <a href={`${regionInPR ? 'tel:787-251-1700' : 'tel:800-766-6786'}`}>
            {regionInPR ? '(787) 251-1700' : '1-800-766-6786'}
          </a>
        </strong>{' '}
        <span>Option #1</span>
      </p>
      {regionInPR ? (
        <></>
      ) : (
        <p>
          <strong>
            Text: <a href="sms:+76667&body=CARE">ROOMS (76667)</a>
          </strong>{' '}
          <span>Keyword CARE</span>
        </p>
      )}
      <p>
        {regionInPR ? (
          <a href="mailto:servicio@roomstogo.pr">servicio@roomstogo.pr</a>
        ) : (
          <a href="mailto:customerservice@roomstogo.com">customerservice@roomstogo.com</a>
        )}
      </p>
      <WidgetWrapper>
        <div className="widget">
          <h4>Track Delivery:</h4>
          <iframe
            title="Delivery Widget"
            src="https://rtg.dispatchtrack.com/track/widget/f6cd6d3"
            height="97px"
            width="252px"
            frameBorder="0"
          />
        </div>
        <div className="widget">
          <h4>Track Service:</h4>
          <iframe
            title="Service Widget"
            src="https://rtgservice.dispatchtrack.com/track/widget/95881fe"
            height="97px"
            width="252px"
            frameBorder="0"
          />
        </div>
      </WidgetWrapper>
    </div>
  </section>
)

const LinkList = () => (
  <section className="grid-x grid-margin-x">
    <div className="cell small-12 large-6">
      <h3>Customer Service:</h3>
      <dl>
        <dt>
          <RTGLink
            data={{
              target: '_blank',
              url: regionInPR
                ? 'https://vault.pactsafe.io/s/e9b4b02e-6b86-4043-82f7-abd86913713a/legal.html#contract-rjvll6vhc'
                : 'https://misc.rtg-prod.com/rtgcom-terms-of-sale.pdf',
              category: 'customer service',
              action: 'online terms of sales click',
              label: 'Online Terms and Conditions of Sale',
            }}
          >
            Online Terms and Conditions of Sale, Limited Product Warranty and Dispute Resolution/Arbitration Agreement
          </RTGLink>
        </dt>
        <dd>Company policies and procedures.</dd>
        <dt>
          <RTGLink
            data={{
              slug: '/customer-service/delivery-shipping',
              category: 'customer service',
              action: 'delivery shipping click',
              label: 'Delivery & Shipping',
            }}
          >
            Delivery &amp; Shipping
          </RTGLink>
        </dt>
        <dd>Delivery policy, shipping area and estimated costs.</dd>
        <dt>
          <RTGLink
            data={{
              slug: '/customer-service/delivery-shipping/international-shipping',
              category: 'customer service',
              action: 'international shipping click',
              label: 'International Shipping',
            }}
          >
            International Shipping
          </RTGLink>
        </dt>
        {regionInPR ? (
          <>
            <dd>
              Rooms To Go offers export services to the entire Caribbean Region. <br />
              Please contact 1-866-766-6846.
            </dd>
          </>
        ) : (
          <>
            <dd>Rooms To Go offers export services at many of our South Florida locations.</dd>
          </>
        )}
        <dt>
          <RTGLink
            data={{
              slug: '/customer-service/common-questions',
              category: 'customer service',
              action: 'common questions click',
              label: 'Common Questions',
            }}
          >
            Common Questions
          </RTGLink>
        </dt>
        <dd>Frequently Asked Questions</dd>
        <dt>
          <RTGLink
            data={{
              slug: '/customer-service/return-policy',
              category: 'customer service',
              action: 'return policy click',
              label: 'Return Policy',
            }}
          >
            Cancellation/Refund/Return Policy
          </RTGLink>
        </dt>
        <dd>View our return, refund and cancellation policies.</dd>
        <dt>
          <RTGLink
            data={{
              slug: '/customer-service/warranty-info',
              category: 'customer service',
              action: 'warranty info click',
              label: 'Warranty Info',
            }}
          >
            Warranty Info
          </RTGLink>
        </dt>
        <dd>Learn about the warranties we offer.</dd>
        {regionInPR ? (
          <></>
        ) : (
          <>
            <dt>
              <RTGLink
                data={{
                  slug: '/customer-service/safety-information',
                  category: 'customer service',
                  action: 'safety information click',
                  label: 'Safety Information',
                }}
              >
                Safety Information
              </RTGLink>
            </dt>
            <dd>Safety is a top priority at Rooms To Go.</dd>
            <dt>
              <RTGLink
                data={{
                  slug: '/patmosrecall',
                  category: 'customer service',
                  action: 'patmos chaise lounge recall click',
                  label: 'Patmos Chaise Lounge Recall',
                }}
              >
                Patmos Chaise Lounge Recall
              </RTGLink>
            </dt>
            <dd>Please click here for important safety information about the Patmos Chaise Lounge Recall.</dd>
            <dt>
              <RTGLink
                data={{
                  slug: '/creeksiderecall',
                  category: 'customer service',
                  action: 'creeksiderecall click',
                  label: "Creekside Kids' 5-Drawer Dresser Recall",
                }}
              >
                Creekside Kids' 5-Drawer Dresser Recall
              </RTGLink>
            </dt>
            <dd>
              Please click here for important safety information about the Creekside Kids' 5-Drawer Dresser Recall.
            </dd>
          </>
        )}
      </dl>
    </div>
    <div className="cell small-12 large-6">
      <h3>Company:</h3>
      <dl>
        {regionInPR ? (
          <>
            <dt>
              <RTGLink
                data={{
                  target: '_blank',
                  url: 'mailto:empleosrtg@outlook.com',
                  category: 'customer service',
                  action: 'careers click',
                  label: 'Employment/Join Our Team',
                }}
              >
                Employment/Join Our Team
              </RTGLink>
            </dt>
            <dd>
              Please, send your resume to: <a href="mailto:empleosrtg@outlook.com">empleosrtg@outlook.com</a>.
            </dd>
          </>
        ) : (
          <>
            <dt>
              <RTGLink
                data={{
                  target: '_blank',
                  url: 'https://careers.roomstogo.com',
                  category: 'customer service',
                  action: 'careers click',
                  label: 'Employment/Join Our Team',
                }}
              >
                Employment/Join Our Team
              </RTGLink>
            </dt>
            <dd>View open positions and apply to join our team.</dd>
          </>
        )}
        <dt>
          <RTGLink
            data={{
              slug: '/customer-service/recycling',
              category: 'customer service',
              action: 'recycling click',
              label: 'Recycling',
            }}
          >
            Recycling
          </RTGLink>
        </dt>
        <dd>Learn more about our award winning recycling program.</dd>
        <dt>
          <RTGLink
            data={{
              slug: '/customer-service/history',
              category: 'customer service',
              action: 'history click',
              label: 'History',
            }}
          >
            History
          </RTGLink>
        </dt>
        <dd>Find out how Rooms To Go became America's #1 independent furniture retailers.</dd>
        <dt>
          <RTGLink
            data={{
              slug: '/about-us/in-the-community',
              category: 'customer service',
              action: 'community click',
              label: 'Community',
            }}
          >
            Community
          </RTGLink>
        </dt>
        <dd>See how Rooms To Go gives back.</dd>
        <dt>
          <RTGLink
            data={{
              slug: '/customer-service/privacy-policy',
              category: 'customer service',
              action: 'privacy click',
              label: 'Privacy',
            }}
          >
            Privacy
          </RTGLink>
        </dt>
        <dd>We care about protecting your safety and privacy - let us tell you how.</dd>
        <dt>
          <RTGLink
            data={{
              url: regionInPR
                ? 'https://vault.pactsafe.io/s/e9b4b02e-6b86-4043-82f7-abd86913713a/legal.html#contract-rjvll6vhc'
                : 'https://misc.rtg-prod.com/rtg-terms-of-use.pdf',
              category: 'customer service',
              action: 'terms of use click',
              label: 'Terms Of Use',
            }}
          >
            Terms Of Use
          </RTGLink>
        </dt>
        <dd>Website specific policies and procedures.</dd>
        {regionInPR ? (
          <>
            <address>
              <strong>MAILING ADDRESS</strong>
              <br />
              Rooms To Go
              <br />
              PMB 465
              <br />
              #90 RIO HONDO AVE.
              <br />
              BAYAMON, PR 00961-3105
              <br />
            </address>
          </>
        ) : (
          <>
            <address>
              <strong>MAILING ADDRESS</strong>
              <br />
              Rooms To Go
              <br />
              11540 Highway 92 East
              <br />
              Seffner, FL 33584
              <br />
            </address>
          </>
        )}
      </dl>
    </div>
  </section>
)

const pageTitle = 'Customer Service - Rooms To Go'

const CustomerService = ({ props }) => {
  useAnalytics({
    title: pageTitle,
    type: 'customer-service',
    path: '/customer-service',
  })
  return (
    <Layout {...props}>
      <Helmet title={pageTitle} />
      <Row>
        <StaticBreadcrumbs />
        <Container>
          <CareLogo src={logo} alt="Rooms To Go Care Logo" />
          <h1>Customer Service</h1>
          <h2>Contact Us</h2>
          <ContactInformation />
          <h2>Additional Information</h2>
          <LinkList />
        </Container>
      </Row>
    </Layout>
  )
}

CustomerService.propTypes = {
  props: PropTypes.any,
}

export default CustomerService
